import React from 'react';

import BlogArchive from 'organisms/BlogArchive';


/**
 * Blog index.
 */
export const query = graphql`
  query {
    posts: allSanityPost(limit: 10, sort: { fields: datePublished, order: DESC }) {
      nodes {
        author {
          id
          name
        }
        category {
          name
          id
          slug {
            current
          }
        }
        datePublished(formatString: "MMMM Do, YYYY")
        featuredImage {
          asset {
            url
            metadata {
              lqip
            }
          }
        }
        featImageAlt
        slug {
          current
        }
        summary
        title
      }
    }
  }
`;
export default function BlogIndex( p ) {

  return(

    <BlogArchive
    type="index"
    initialPosts={ p.data.posts.nodes }
    />

);

}